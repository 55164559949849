import axiosInstance from '@/api/axiosInstance';

function generateUniqueId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

const actions = {
  updateTicketQuantity({ commit }, quantity) {
    // Crea una nueva lista de pasajeros con la cantidad indicada
    const newPassengers = Array.from({ length: quantity }, () => ({
      id: generateUniqueId(), // Genera un ID único para cada nuevo pasajero
      name: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'adulto',
      country: '',
      state: '',
    }));

    // Actualiza la lista de pasajeros y la cantidad de boletos en el estado
    commit('UPDATE_PASSENGERS', newPassengers);
    commit('SET_DATA_RESERVATION', { ticket_quantity: quantity });
  },
  async fetchTransportschedules({ commit }, queryParams) {
    try {
      const url = '/transportschedules';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const transportSchedules = response.data.payload.transportSchedules || [];
        commit('SET_DATA', { key: 'transportSchedules', data: transportSchedules });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },
  async fetchReservationValues({ commit }, queryParams) {
    try {
      const url = '/reservations';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const transportSchedule = response.data.payload.transportSchedule || [];
        const ticketTypes = response.data.payload.ticketTypes || [];
        commit('SET_DATA_RESERVATION', { transportSchedule: transportSchedule });
        commit('SET_DATA_RESERVATION', { ticketTypes: ticketTypes });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },
  async fetchVehicles({ commit }, queryParams) {
    try {
      const url = '/vehicles';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const vehicles = response.data.payload.vehicles || [];
        commit('SET_DATA_RESERVATION', { vehicles: vehicles });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },
  async createOrder({ commit }, orderData) {
    try {
      const url = '/orders/create';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const data = response.data;
        commit('SET_DATA', { key: 'formSend', data: true });

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async getOrderData({ commit }, orderData) {
    try {
      const url = '/orders/get_info';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const order = response.data.payload.order || [];
        commit('SET_DATA', { key: 'orderData', data: order });

        return order;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async updateOrderPaypal({ commit }, orderData) {
    try {
      const url = '/orders/update_paypal';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const data = response.data;
        commit('SET_DATA', { key: 'formSend', data: true });

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
};

export default actions;
