<template>
  <div class="tours">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-3 tours-images">
          <figure v-for="n in 3" :key="n" class="image is-1by1">
            <img class="tours-img" :src="require(`@/assets/images/privados/${n}.png`)" alt="Logo" />
          </figure>
        </div>
        <div class="column is-9 tours-content">
          <div class="box title first-text has-background-info">
            <p class="has-text-white">TOUR PRIVADOS</p>
          </div>
          <h3 class="title first-text">TOURS PRIVADOS</h3>
          <p class="text">Tour con servicio de bebidas a bordo del autobús (tequila, refresco y agua).</p>
          <p class="text">
            Esta experiencia se puede realizar de martes a viernes o domingos dependiendo de la disponibilidad, les hacemos un tour privado y
            personalizado de acuerdo a sus necesidades y se hace una cotización en base a la cantidad de personas. (Mínimo 20)
          </p>
          <div class="reservations box has-background-info">
            <a
              class="title has-text-white"
              href="https://wa.me/523335592398?text=¡Hola!%20Necesito%20información%20sobre%20los%20tours%20a%20Tequila,%20Jalisco."
              target="_blank"
              >INFORMES Y RESERVACIONES: <i class="fab fa-whatsapp"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>