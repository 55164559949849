<template>
  <section class="not-found hero is-medium">
    <div class="hero-body">
      <div class="container has-text-centered">
        <span class="icon is-large"><i class="mdi mdi-emoticon-sad mdi-48px"></i></span>
        <h1 class="title">{{ computedTitle }}</h1>
        <br />
        <h2 class="subtitle">{{ computedSubtitle }}</h2>
        <b-button v-if="showUrl" class="vat-btn subtitle has-text-white" tag="a" :href="url" label="Regresar" type="is-info" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showUrl: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: '/',
    },
  },
  computed: {
    computedTitle() {
      return this.title !== '' ? this.title : this.translatedText('404_title_default');
    },
    computedSubtitle() {
      return this.subtitle !== '' ? this.subtitle : this.translatedText('404_subtitle_default');
    },
  },
};
</script>
