<template>
  <div class="home-page">
    <div class="home-videos">
      <div class="container">
        <div class="columns">
          <div class="column is-12">
            <iframe
              src="https://www.youtube.com/embed/I-mZdPuU7ug"
              title="¡Vive la Mágia del Tequila en Tequila Express!"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
 <!--          <div class="column is-6">
            <img class="video" src="@/assets/images/video-patrocinadores.png" alt="Logo" />
          </div> -->
        </div>
      </div>
    </div>
<!-- 
    <div class="home-experiences">
      <div class="container">
        <div class="columns">
          <div class="column is-12">
            <img class="title-experiences" src="@/assets/images/text-experiencias.png" alt="Logo" />
          </div>
        </div>
        <div class="experience-cards">
          <div class="columns">
            <div class="column is-3">
              <router-link :to="{ name: 'tour-diamante' }" class="card-link">
                <p class="card-title has-text-secondary">
                  TOUR <br />
                  DIAMANTE
                </p>
                <figure class="image is-1by1">
                  <img class="card-img" src="@/assets/images/experiencia-diamante.png" alt="Logo" />
                </figure>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link :to="{ name: 'tour-oro' }" class="card-link">
                <p class="card-title has-text-warning">
                  TOUR <br />
                  ORO
                </p>
                <figure class="image is-1by1">
                  <img class="card-img" src="@/assets/images/experiencia-oro.png" alt="Logo" />
                </figure>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link :to="{ name: 'tour-plata' }" class="card-link">
                <p class="card-title has-text-gray">
                  TOUR <br />
                  PLATA
                </p>
                <figure class="image is-1by1">
                  <img class="card-img" src="@/assets/images/experiencia-plata.png" alt="Logo" />
                </figure>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link :to="{ name: 'tours-privados' }" class="card-link">
                <p class="card-title has-text-info">
                  TOURS <br />
                  PRIVADOS
                </p>
                <figure class="image is-1by1">
                  <img class="card-img" src="@/assets/images/experiencia-privados.png" alt="Logo" />
                </figure>
              </router-link>
            </div>
          </div>
        </div>
        <div class="next-dates">
          <div class="columns">
            <div class="column is-4">
              <div class="box has-background-secondary">
                <p class="title has-text-white">JUNIO</p>
                <p class="subtitle has-text-white">SABADOS: 8, 15, 22 Y 29</p>
              </div>
            </div>
            <div class="column is-4">
              <div class="box has-background-info">
                <p class="title">JULIO</p>
                <p class="subtitle">SABADOS: 6, 13, 20 Y 27</p>
              </div>
            </div>
            <div class="column is-4">
              <div class="box has-background-secondary">
                <p class="title has-text-white">AGOSTO</p>
                <p class="subtitle has-text-white">SABADOS: 3, 10, 17 Y 31</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Web',
  data() {
    return {};
  },
  computed: {},
};
</script>