<template>
  <div class="seats">
    <div class="columns is-multiline">
      <div class="column is-5">
        <b-carousel-list :data="vehicles" :items-to-show="1" :repeat="true" :arrow-hover="false">
          <template #item="vehicle">
            <div class="map box">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 front map-info mb-5">
                  <p>Tren {{ vehicle.vehicle_number }}</p>
                </div>

                <div class="column is-12 front map-info">
                  <p>Frente</p>
                </div>

                <div class="seats-rows column is-3">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 0)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-3">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 1)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-3">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 2)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, hidden: seat.hidden }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-3">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 3)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, wc: seat.wc }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-3">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 4)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, wc: seat.wc }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="column is-12 under map-info">
                  <p>Posterior</p>
                </div>
              </div>
            </div>
          </template>
        </b-carousel-list>
      </div>
      <div class="column is-7">
        <div class="box">
          <h2 class="title is-4">Información sobre los asientos</h2>
          <ul>
            <li>
              <p><span class="info-icon has-background-secondary"></span>Disponible</p>
            </li>
            <li>
              <p><span class="info-icon has-background-warning"></span>Seleccionado</p>
            </li>
            <li>
              <p><span class="info-icon"></span>Ocupado</p>
            </li>
            <li>
              <p><span class="info-icon has-background-info"></span>Baños</p>
            </li>
            <!--             <b-button class="mt-5" type="is-primary" expanded>Reservar Asientos</b-button>
 -->
          </ul>
        </div>
        <div class="box bus-map">
          <h2 class="title is-4">Mapa</h2>
          <figure class="image">
            <img class="card-img" src="@/assets/images/vagon-ejecutivo.png" alt="Logo" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      seats: [
        [
          // Fila 3
          { id: '1D', label: '1D', available: true, selected: false },
          { id: '2D', label: '2D', available: true, selected: false },
          { id: '3D', label: '3D', available: true, selected: false },
          { id: '4D', label: '4D', available: true, selected: false },
          { id: '5D', label: '5D', available: true, selected: false },
          { id: '6D', label: '6D', available: true, selected: false },
          { id: '7D', label: '7D', available: true, selected: false },
          { id: '8D', label: '8D', available: true, selected: false },
          { id: '9D', label: '9D', available: true, selected: false },
          { id: '10D', label: '10D', available: true, selected: false },
          { id: '11D', label: '11D', available: true, selected: false },
          { id: '12D', label: '12D', available: true, selected: false },
          { id: '13D', label: '13D', available: true, selected: false },
          { id: '14D', label: '14D', available: true, selected: false },
          { id: '15D', label: '15D', available: true, selected: false },
          { id: '16D', label: '16C', available: true, selected: false },
        ],
        [
          // Fila 3
          { id: '1C', label: '1C', available: true, selected: false },
          { id: '2C', label: '2C', available: true, selected: false },
          { id: '3C', label: '3C', available: false, selected: false },
          { id: '4C', label: '4C', available: true, selected: false },
          { id: '5C', label: '5C', available: true, selected: false },
          { id: '6C', label: '6C', available: true, selected: false },
          { id: '7C', label: '7C', available: true, selected: false },
          { id: '8C', label: '8C', available: true, selected: false },
          { id: '9C', label: '9C', available: true, selected: false },
          { id: '10C', label: '10C', available: true, selected: false },
          { id: '11C', label: '11C', available: true, selected: false },
          { id: '12C', label: '12C', available: true, selected: false },
          { id: '13C', label: '13C', available: true, selected: false },
          { id: '14C', label: '14C', available: true, selected: false },
          { id: '15C', label: '15C', available: true, selected: false },
          { id: '16C', label: '16C', available: true, selected: false },
        ],
        [
          // Fila 2
          { id: '1B', label: '1B', available: true, selected: false },
          { id: '2B', label: '2B', available: true, selected: false },
          { id: '3B', label: '3B', available: true, selected: false },
          { id: '4B', label: '4B', available: true, selected: false },
          { id: '5B', label: '5B', available: false, selected: false },
          { id: '6B', label: '6B', available: true, selected: false },
          { id: '7B', label: '7B', available: true, selected: false },
          { id: '8B', label: '8B', available: true, selected: false },
          { id: '9B', label: '9B', available: true, selected: false },
          { id: '10B', label: '10B', available: true, selected: false },
          { id: '11B', label: '11B', available: true, selected: false },
          { id: '12B', label: '12B', available: true, selected: false },
          { id: '13B', label: '13B', available: true, selected: false },
          { id: '14B', label: '14B', available: false, selected: false },
          { id: '15B', label: '15B', available: true, selected: false },
          { id: '16B', label: '16B', available: true, selected: false },
        ],
        [
          // Fila 1
          { id: '1A', label: '1A', available: true, selected: false },
          { id: '2A', label: '2A', available: true, selected: false },
          { id: '3A', label: '3A', available: true, selected: false },
          { id: '4A', label: '4A', available: false, selected: false },
          { id: '5A', label: '5A', available: true, selected: false },
          { id: '6A', label: '6A', available: true, selected: false },
          { id: '7A', label: '7A', available: true, selected: false },
          { id: '8A', label: '8A', available: true, selected: false },
          { id: '9A', label: '9A', available: true, selected: false },
          { id: '10A', label: '10A', available: true, selected: false },
          { id: '11A', label: '11A', available: true, selected: false },
          { id: '12A', label: '12A', available: false, selected: false },
          { id: '13A', label: '13A', available: true, selected: false },
          { id: '14A', label: '14A', available: true, selected: false },
          { id: '15A', label: '15A', available: true, selected: false },
          { id: '16A', label: '16A', available: true, selected: false },
        ],
        // Añade más filas según sea necesario
      ],
      selectedSeats: [],
      seatsArrayNew: [],
    };
  },
  computed: {
    ...mapState({
      schedule_id: (state) => state.reservations.reservation.transportSchedule.id,
      transport_type: (state) => state.reservations.reservation.transportSchedule.transport_type,
      ticket_type: (state) => state.reservations.reservation.ticket_type,
      ticket_quantity: (state) => state.reservations.reservation.ticket_quantity,
      vehicles: (state) => state.reservations.reservation.vehicles,
      reservationSeats: (state) => state.reservations.reservation.seats,
    }),
  },
  async mounted() {
    await this.loadVehicles();
  },
  watch: {
    async ticket_type(newVal) {
      if (newVal === 'ejecutivo' || newVal === 'bar') {
        await this.loadVehicles();
      }
    },
    async selectedSeats(newVal) {
      const seats = { ['seats']: newVal };
      this.setDataReservation(seats);
    },
  },
  methods: {
    ...mapActions({
      fetchVehicles: 'reservations/fetchVehicles',
    }),
    ...mapMutations({
      setDataReservation: 'reservations/SET_DATA_RESERVATION',
    }),
    toggleSeat(seat) {
      // Verificar si el asiento ya está seleccionado
      const seatIndex = this.selectedSeats.findIndex((s) => s.id === seat.id);

      if (seatIndex !== -1) {
        // Si el asiento está seleccionado, lo deseleccionamos
        seat.selected = false;
        this.selectedSeats.splice(seatIndex, 1); // Eliminar el objeto del asiento de la selección
      } else {
        if (this.selectedSeats.length >= this.ticket_quantity) {
          // Mostrar mensaje de error si se excede la cantidad permitida
          Swal.fire({
            icon: 'error',
            title: '¡Error!',
            text: `Has alcanzado el límite de selección de asientos. Solo puedes seleccionar hasta ${this.ticket_quantity} asiento${
              this.ticket_quantity > 1 ? 's' : ''
            }.`,
            confirmButtonText: 'Aceptar',
          });
          return; // Salir del método si se excede la cantidad
        }

        // Si el asiento no está seleccionado, lo seleccionamos
        seat.selected = true;
        this.selectedSeats.push(seat);
      }
    },
    async loadVehicles() {
      let vehicleType = 'bus';

      if (this.transport_type === 'train' && this.ticket_type === 'ejecutivo') {
        vehicleType = 'train';
      }

      if (this.transport_type === 'train' && this.ticket_type === 'bar') {
        vehicleType = 'train-bar';
      }

      const params = {
        transport_schedule_id: this.schedule_id,
        vehicle_type: vehicleType,
      };

      await this.fetchVehicles(params);

      this.updateSeatsAvailability();
    },
    updateSeatsAvailability() {
              console.log('hola mundo');
      // Crear una copia del array que contiene la estructura de vehículos y sus asientos
      this.seatsArrayNew = this.vehicles.map((vehicle) => {

        console.log(vehicle);
        // Hacer una copia de los asientos
        const seatsForVehicle = this.seats.map((row) => {
          return row.map((seat) => {
            // Buscar el asiento correspondiente en el vehículo
            const vehicleSeat = vehicle.seats.find((v) => v.seat_number === seat.label);
            // Devolver el asiento con la disponibilidad actualizada
            return {
              ...seat,
              id: vehicleSeat ? vehicleSeat.id : seat.id,
              vehicleId: vehicle.id,
              vehicleNumber: vehicle.vehicle_number,
              available: vehicleSeat && vehicleSeat.is_available == '1' && vehicleSeat.is_reserved == '0' ? true : false,
            };
          });
        });

        return {
          vehicleId: vehicle.id, // Suponiendo que cada vehículo tiene un ID
          vehicleNumber: vehicle.vehicle_number, // Número del vehículo
          seats: seatsForVehicle,
        };
      });
    },
    getSeatsForVehicle(vehicleId, index) {
      // Buscar el vehículo en la lista de vehículos
      const vehicle = this.seatsArrayNew.find((v) => v.vehicleId === vehicleId);

      // Si el vehículo existe, retornar sus asientos
      return vehicle ? vehicle.seats[index] : []; // Retornar un array vacío si no se encuentra el vehículo
    },
  },
};
</script>

<style>
.map {
  max-width: 400px;
  margin: auto;
  display: block;
}

.map-info {
  background: #004e6d;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.seats-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seat {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #000;
  flex-direction: column;
}

.seat input {
  display: none;
}

.seat label {
  cursor: pointer;
  font-size: 12px;
}

.seat.available {
  background-color: #091d61;
  color: #fff;
  border: none;
}

.seat.selected {
  background-color: #b5891a;
  border: none;
}

.seat.hidden {
  background-color: transparent;
  border: none;
  color: transparent;
}

.seat.wc {
  background-color: #29abe2;
  border: none;
  color: transparent;
}

.info-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #000;
}

.bus-map img {
  width: auto !important;
  height: 500px !important;
  margin: auto;
  display: block;
}

.seats .carousel-arrow span.icon.has-icons-left,
.seats .carousel-arrow span.icon.has-icons-right {
  top: 35px !important;
}
</style>
