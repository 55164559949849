<template>
  <div class="columns is-centered is-vcentered is-multiline">
    <div class="column is-half">
      <div id="paypal-button-container"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'paymentConfirmation',
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paypalKey: process.env.VUE_APP_PAYPAL_KEY,
    };
  },
  mounted() {
    this.initPaypal();
  },
  watch: {
    reservation: {
      handler() {
        this.initPaypal();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      createOrder: 'reservations/createOrder',
    }),
    initPaypal() {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalKey}&currency=MXN`;
      script.onload = () => {
        this.renderPayPalButton();
      };
      document.body.appendChild(script);
    },
    renderPayPalButton() {
      const { ticket_type, ticket_quantity, ticketPrice, transportSchedule } = this.reservation;

      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: 'MXN',
                    value: this.reservation.total.toFixed(2), // Total de la reserva
                    breakdown: {
                      item_total: {
                        currency_code: 'MXN',
                        value: this.reservation.subtotal.toFixed(2), // Subtotal
                      },
                      shipping: {
                        currency_code: 'MXN',
                        value: '0.00', // Si aplica, incluye costo de envío
                      },
                      tax_total: {
                        currency_code: 'MXN',
                        value: '0.00', // Si aplica, incluye impuestos
                      },
                    },
                  },
                  description: `Reserva para ${ticket_quantity} boletos de tipo ${ticket_type} (${transportSchedule.transport_type})`, // Descripción mejorada
                  custom_id: this.reservation.id, // ID personalizado
                  invoice_number: `INV-${this.reservation.id}`, // Número de factura
                  items: [
                    {
                      name: `Boleto ${ticket_type}`,
                      description: `Boleto para ${ticket_quantity} personas, tipo: ${ticket_type}, transporte: ${transportSchedule.transport_type}, horarios: ${transportSchedule.departure_time} - ${transportSchedule.arrival_time}`,
                      sku: transportSchedule.id + transportSchedule.transport_type + transportSchedule.departure_time,
                      unit_amount: {
                        currency_code: 'MXN',
                        value: ticketPrice, // Precio por boleto
                      },
                      quantity: ticket_quantity, // Cantidad de boletos
                    },
                  ],
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const details = await actions.order.capture();

              if (details && details.id) {
                console.log('Detalles de la transacción:', details);

                // Crear el objeto orderData fusionando la reserva con los detalles de pago
                const orderData = {
                  ...this.reservation,
                  paymentDetails: {
                    details: details,
                    paymentId: details.purchase_units[0].payments.captures[0].id,
                    transactionId: details.id,
                    payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                    payerEmail: details.payer.email_address,
                    status: details.status,
                    amount: details.purchase_units[0].amount.value,
                    currency: details.purchase_units[0].amount.currency_code,
                    orderId: data.orderID,
                  },
                  passengers: this.reservation.passengers || [], // Agregar datos de pasajeros aquí
                };

                console.log('Datos a enviar en createOrder:', orderData);

                // Llama a createOrder con los detalles completos
                this.createOrder(orderData);

                // SweetAlert2 para mostrar el mensaje de éxito
                await Swal.fire({
                  title: 'Transacción completada',
                  text: 'Transacción completada por ' + details.payer.name.given_name,
                  icon: 'success',
                  confirmButtonText: 'Aceptar',
                });

                // Recargar la página después de que el usuario cierre el modal
                location.reload();
              } else {
                throw new Error('No se pudieron obtener los detalles de la transacción');
              }
            } catch (error) {
              console.error('Error capturando la transacción:', error);
              await Swal.fire({
                title: 'Error en la transacción',
                text: 'Hubo un problema al procesar el pago. Por favor, inténtalo de nuevo.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          },
          onError: (err) => {
            console.error('PayPal Checkout Error:', err);
            Swal.fire({
              title: 'Error',
              text: 'Ocurrió un error durante el proceso de pago.',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          },
        })
        .render('#paypal-button-container');
    },
  },
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí */
</style>
