// Import
import Vue from 'vue';
import Router from 'vue-router';
import Web from './web';

// Use Vue Router
Vue.use(Router);

// Start Vue Router
const router = new Router({
  mode: 'history', // Descomenta esta línea para habilitar el modo de historial
  routes: [
    ...Web,
  ],
});

// Export
export default router;
