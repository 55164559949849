const initialState = {
  loading: {
    isLoading: false,
    isFullPage: true,
  },
};

const mutations = {
  SET_LOADING(state, value) {
    state.loading.isLoading = value;
  },
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  INIT_STATE(state) {
    state.loading = initialState.loading;
  },
};

export default mutations;
