import Vue from 'vue';
import Vuex from 'vuex';
import ui from './ui';
import payment from './payment';
import reservations from '@/modules/web/reservations/store/';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui,
    payment,
    reservations,
  },
});
