const actions = {
  setLogoHeader({ commit }, isTrain) {
    if (isTrain) {
      commit('SET_DATA', { key: 'headerLogoUrl', data: require('@/assets/images/logo-tren-express.png') });
      commit('SET_DATA', { key: 'isTrain', data: true });
    } else {
      commit('SET_DATA', { key: 'headerLogoUrl', data: require('@/assets/images/logo-tequila.png') });
      commit('SET_DATA', { key: 'isTrain', data: false });
    }
  },
};

export default actions;