<template>
  <header class="header">
    <div class="container">
      <div class="columns">
        <div class="column">
          <b-navbar :centered="true">
            <template #brand>
              <b-navbar-item v-if="!isTrain" tag="router-link" :to="{ name: 'home' }">
                <img class="img-logo" :src="headerLogoUrl" alt="Logo" />
              </b-navbar-item>
              <b-navbar-item v-else tag="a" href="https://www.tequilaexpress.mx/" target="_blank">
                <img class="img-logo" :src="headerLogoUrl" alt="Logo" />
              </b-navbar-item>
            </template>
            <template #start v-if="!isTrain">
              <b-navbar-item tag="router-link" :to="{ name: 'about-us' }"> ¿Quienes somos? </b-navbar-item>
              <b-navbar-dropdown label="Experiencias">
                <b-navbar-item tag="router-link" :to="{ name: 'tour-diamante' }"> Tour Diamante </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'tour-oro' }"> Tour Oro </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'tour-plata' }"> Tour Plata </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'tours-privados' }"> Tours Privados </b-navbar-item>
              </b-navbar-dropdown>
              <b-navbar-item tag="router-link" :to="{ name: 'gallery' }"> Galería </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ name: 'hotels' }"> Hoteles </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ name: 'reservations' }">
                <img class="img-tickets" src="@/assets/images/tickets.png" alt="Logo" />
              </b-navbar-item>
            </template>

            <template #end>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                <img class="img-tickets" src="@/assets/images/logo-camara.png" alt="Logo" />
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  async mounted() {

  },
  computed: {
    ...mapState({
      headerLogoUrl: (state) => state.ui.headerLogoUrl,
      isTrain: (state) => state.ui.isTrain,
    }),
  },
  methods: {
  },
};
</script>
