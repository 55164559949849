<template>
  <div class="gallery">
    <div class="container">
      <div class="columns is-multiline">
        <div v-for="n in 12" :key="n" class="column is-3 gallery-images">
          <figure class="image is-1by1">
            <img class="gallery-img" :src="require(`@/assets/images/gallery/${n}.png`)" alt="Logo" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>