import Layout from '@/layouts/web/index.vue';
import Home from '@/modules/web/index.vue';
import Reservations from '@/modules/web/reservations/index.vue';
import ReservationsDates from '@/modules/web/reservations/travelDateSelection.vue';
import TicketSelection from '@/modules/web/reservations/ticketTypeSelection.vue';
import ConfirmateOrder from '@/modules/web/reservations/confirmateOrder.vue';
import NotFound from '@/modules/web/not-found.vue';
import AboutUs from '@/modules/web/about-us.vue';
import Gallery from '@/modules/web/gallery.vue';
import Hotels from '@/modules/web/hotels.vue';
import Diamante from '@/modules/web/tours/diamante.vue';
import Oro from '@/modules/web/tours/oro.vue';
import Plata from '@/modules/web/tours/plata.vue';
import Privados from '@/modules/web/tours/privados.vue';

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
      },
      {
        path: '/quienes-somos',
        component: AboutUs,
        name: 'about-us',
      },
      {
        path: '/galeria',
        component: Gallery,
        name: 'gallery',
      },
      {
        path: '/hoteles',
        component: Hotels,
        name: 'hotels',
      },
      {
        path: 'tour-diamante',
        component: Diamante,
        name: 'tour-diamante',
      },
      {
        path: 'tour-oro',
        component: Oro,
        name: 'tour-oro',
      },
      {
        path: 'tour-plata',
        component: Plata,
        name: 'tour-plata',
      },
      {
        path: 'tours-privados',
        component: Privados,
        name: 'tours-privados',
      },
      {
        path: '/reservaciones',
        component: Reservations,
        name: 'reservations',
      },
      {
        path: '/reservaciones/autobus',
        component: ReservationsDates,
        name: 'reservations-bus',
      },
      {
        path: '/reservaciones/tren',
        component: ReservationsDates,
        name: 'reservations-train',
      },
      {
        path: '/reservaciones/seleccion-boletos/tren/:reservationId',
        component: TicketSelection,
        name: 'ticket-selection-tren',
        props: true,
      },
      {
        path: '/reservaciones/seleccion-boletos/autobus/:reservationId',
        component: TicketSelection,
        name: 'ticket-selection-autobus',
        props: true,
      },
      {
        path: '/reservaciones/confirmate-order/:code',
        component: ConfirmateOrder,
        name: 'confimate-order',
        props: true,
      },
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'NotFound'
  }
];
