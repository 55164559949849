<template>
  <div class="tours">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-3 tours-images">
          <figure v-for="n in 3" :key="n" class="image is-1by1">
            <img class="tours-img" :src="require(`@/assets/images/diamante/${n}.png`)" alt="Logo" />
          </figure>
        </div>
        <div class="column is-9 tours-content">
          <div class="box title first-text has-background-secondary">
            <p class="has-text-white">TOUR DIAMANTE</p>
          </div>
          <h3 class="title first-text">TOUR DIAMANTE $2,200.00</h3>
          <p class="text">
            Es un tour personalizado para 10 personas como mínimo, el transporte es en camioneta sprinter con guía privado y servicio de bebidas
            (tequila, refresco, cerveza y agua). Nosotros pasamos por el grupo a donde nos indiquen dentro de la zona metropolitana.
          </p>
          <h3 class="title">Itinerario</h3>
          <p class="text">
            09:30 Llegada de camioneta sprinter a la sede y entrega de pulseras <br />
            10:00 Salida hacia Tequila <br />
            11:15 Llegada a los campos agaveros de Casa Sauza, demostración de la jima del agave y plantación <br />
            12:30 Visita a bodega de añejamiento“La Constancia” y fábrica “La Perseverancia” de Casa Sauza. <br />
            13:30 Dinámica de preparar su propio cantarito <br />
            14:00 Comida (parrillada) <br />
            14:30 Espectáculo con mariachi, ballet folclórico y cantantes (lugares preferenciales) <br />
            15:45 Visita a pie al centro de Tequila <br />
            16:45 Regreso a Guadalajara <br />
            18:15 Llegada a la sede.<br />
          </p>
          <div class="reservations box has-background-secondary">
            <a
              class="title has-text-white"
              href="https://wa.me/523335592398?text=¡Hola!%20Necesito%20información%20sobre%20los%20tours%20a%20Tequila,%20Jalisco."
              target="_blank"
              >INFORMES Y RESERVACIONES: <i class="fab fa-whatsapp"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>