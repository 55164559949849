const mutations = {
  setStripeInstance(state, stripeInstance) {
    state.stripe = stripeInstance;
  },
  setPaymentToken(state, token) {
    state.paymentToken = token;
  },
  setPaymentIntent(state, paymentIntent) {
    state.paymentIntent = paymentIntent;
  },
};

export default mutations;
