const state = {
  loading: {
    isLoading: false,
    isFullPage: true,
  },
  menuOpen: false,
  isMobile: false,
  headerLogoUrl: require('@/assets/images/logo-tequila.png'),
  headerLogoClass: 'logo-azul',
  isTrain: false,
};

export default state;