// languagePlugin.js

const defaultLanguage = process.env.LANG_DEFAULT || 'es';

const languagePlugin = {
  currentLanguage: localStorage.getItem('language') || defaultLanguage,

  changeLanguage(language) {
    this.currentLanguage = language;
    localStorage.setItem('language', language);
  },

  translatedText(key) {
    const dictionary = require(`./locales/${this.currentLanguage}.json`);
    console.log(dictionary);
    return dictionary[key] || key;
  }
};

export default languagePlugin;
