<template>
  <div class="hotels">
    <div class="container">
      <div class="columns is-multiline is-centered hotels-images">
        <div v-for="n in 4" :key="n" class="column is-6">
          <figure class="image">
            <img class="hotels-img" :src="require(`@/assets/images/hotels/${n}.png`)" alt="Logo" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>