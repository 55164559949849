<template>
  <div class="travel-date-page py-6">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12 box">
          <h2 class="title">{{ reservationTitle }}</h2>
        </div>
        <div class="column is-3">
          <div class="reservations-filters box">
            <label class="label">Selecciona una fecha para tu viaje</label>
            <Datepicker type="month" @date-selected="handleDateSelected" icon="calendar-today" />
          </div>
        </div>

        <div class="column is-9">
          <div class="reservations-schedules">
            <div v-if="schedules && schedules.length > 0">
              <div class="schedule" v-for="(schedule, index) in schedules" :key="index">
                <div class="columns is-multiline box">
                  <div class="column is-4">
                    <div class="schedule-logo">
                      <b-image :class="isTrain ? 'logo-tren' : 'logo-autobus'" :src="logo" alt="Logo" ratio="601by235" rounded></b-image>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="schedule-date">
                      <p class="title">Fecha</p>
                      <p class="subtitle">{{ formatDate(schedule.departure_time) }}</p>
                    </div>
                  </div>
                  <div class="column is-2">
                    <div class="schedule-date-start">
                      <p class="title">Salida</p>
                      <p class="subtitle">{{ formatTime(schedule.departure_time) }}</p>
                    </div>
                  </div>
                  <div class="column is-2">
                    <div class="schedule-date-end">
                      <p class="title">Regreso</p>
                      <p class="subtitle">{{ formatTime(schedule.arrival_time) }}</p>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="schedule-chekout">
                      <div class="has-text-right">
                        <b-button
                          tag="router-link"
                          :to="{ name: urlTicketSelection, params: { reservationId: schedule.id } }"
                          type="is-secondary"
                          rounded
                        >
                          ¡Reserva tu lugar ahora!
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="has-text-centered is-size-5 has-text-weight-bold">
                No hay salidas disponibles para el mes seleccionado. Por favor, selecciona otro mes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Datepicker from '@/components/common/datepicker.vue';

export default {
  name: 'ReservationsDates',
  components: {
    Datepicker,
  },
  data() {
    return {
      queryParams: {},
      /*       schedules: [
        {
          id: 1,
          date: '28 de septiembre del 2024',
          departure: '09:00 AM',
          return: '6:15 PM',
          details: '',
          date_id: 23,
          time_id: 230,
          time: '09:00 AM',
          transport: this.transport(),
        },
        {
          id: 2,
          date: '05 de octubre del 2024',
          departure: '09:00 AM',
          return: '6:15 PM',
          details: '',
          date_id: 23,
          time_id: 230,
          time: '09:00 AM',
          transport: this.transport(),
        },
        {
          id: 3,
          date: '12 de octubre del 2024',
          departure: '09:00 AM',
          return: '6:15 PM',
          details: '',
          date_id: 23,
          time_id: 230,
          time: '09:00 AM',
          transport: this.transport(),
        },
      ], */
    };
  },
  async mounted() {
    this.setLogoHeader(this.$route.path.includes('tren'));
    await this.loadData();
  },
  beforeDestroy() {
    this.setLogoHeader(false);
  },
  computed: {
    ...mapState({
      schedules: (state) => state.reservations.transportSchedules,
      isTrain: (state) => state.ui.isTrain,
    }),
    loadIsTrain() {
      this.setLogoHeader(this.$route.path.includes('tren'));
      return this.isTrain;
    },
    reservationTitle() {
      return this.isTrain ? '¡Viaje en Tren a Tequila, Jalisco!' : '¡Viaje en Autobús a Tequila, Jalisco!';
    },
    reservationSubTitle() {
      return this.isTrain ? 'Reserva tu emocionante viaje en tren' : 'Reserva tu cómodo viaje en autobús';
    },
    logo() {
      return this.isTrain ? require('@/assets/images/icono-tren.png') : require('@/assets/images/icono-autobus.png');
    },
    urlTicketSelection() {
      return this.isTrain ? 'ticket-selection-tren' : 'ticket-selection-autobus';
    },
  },
  methods: {
    ...mapActions({
      setLogoHeader: 'ui/setLogoHeader',
      fetchTransportschedules: 'reservations/fetchTransportschedules',
    }),
    async handleDateSelected(date) {
      const selectedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const selectedYear = date.getFullYear();

      this.queryParams.month = selectedMonth;
      this.queryParams.year = selectedYear;

      await this.loadData();
    },
    transport() {
      return this.$route.path.includes('tren') ? 'tren' : 'autobus';
    },
    async loadData() {
      this.queryParams.transport_type = this.isTrain ? 'train' : 'bus';
      await this.fetchTransportschedules(this.queryParams);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', options);
    },
    formatTime(dateString) {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const time = new Date(dateString);
      return time.toLocaleTimeString('es-ES', options);
    },
  },
};
</script>

<style lang="scss">
.travel-date-page {
  .title {
    font-size: 1.2rem;
    font-weight: 900;
    color: #0e1c2b;
    margin-bottom: 20px;
  }

  .schedule {
    padding: 30px 10px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: bold;
    color: #0e1c2b;
  }

  .schedule-chekout {
    margin-top: 15px;
  }

  .schedule-logo img {
    object-fit: contain;
  }
}
</style>
