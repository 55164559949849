const state = {
  reservation: {
    ticket_type: '',
    ticket_quantity: 1,
    ticketPrice: 0,
    transportSchedule: {},
    ticketTypes: [],
    passengers: [],
    vehicles: [],
    seats: [],
    emergencyNumber: '',
    subtotal: 0,
    total: 0,
    discount: 0,
    coupon_id: '',
  },
  transportSchedules: [],
  formSend: false,
  reloadList: false,
  orderData: {},
};

export default state;
