<template>
  <div class="seat-selection">
    <SeatsBus v-if="transport_type === 'bus'"></SeatsBus>
    <SeatsTrain v-else-if="transport_type === 'train' && ticket_type === 'ejecutivo'"></SeatsTrain>
    <SeatsTrainBar v-else-if="transport_type === 'train' && ticket_type === 'bar'"></SeatsTrainBar>
  </div>
</template>

<script>
import SeatsBus from './seatsBus.vue';
import SeatsTrain from './seatsTrain.vue';
import SeatsTrainBar from './seatsTrainBar.vue';

import { mapState } from 'vuex';

export default {
  components: {
    SeatsBus,
    SeatsTrain,
    SeatsTrainBar,
  },
  data() {
    return {
      selectedSeats: [],
    };
  },
  computed: {
    ...mapState({
      schedule_id: (state) => state.reservations.reservation.transportSchedule.id,
      transport_type: (state) => state.reservations.reservation.transportSchedule.transport_type,
      ticket_type: (state) => state.reservations.reservation.ticket_type,
    }),
  },
  methods: {},
};
</script>

<style scoped>
.seat-selection {
  padding: 20px 0;
}
</style>
