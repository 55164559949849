<script>
import { mapState } from 'vuex'; // Importa mapState desde Vuex

import headerRoot from '@/components/web/header';
import footerRoot from '@/components/web/footer';
import loadingRoot from '@/components/common/loading';

export default {
  name: 'Root',
  components: {
    headerRoot,
    footerRoot,
    loadingRoot,
  },
  data() {
    return {
    };
  },
  computed: {
    // Mapea la propiedad isMobile del estado de Vuex
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
  mounted() {
    // Verificar el ancho de la pantalla al montar el componente
    this.checkMobile();
    // Agregar un event listener para cambiar isMobile si la ventana se redimensiona
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    // Asegurarse de quitar el event listener al destruir el componente
    window.removeEventListener('resize', this.checkMobile);
  },
  watch: {
  },
  methods: {
    checkMobile() {
      // Obtener el ancho de la ventana del navegador
      const screenWidth = window.innerWidth;
      // Si el ancho es mayor que 1023px, entonces isMobile debe ser false
      const isMobile = screenWidth <= 1023;
      // Actualizar el estado en Vuex
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
  },
};
</script>

<template>
  <div class="main-root">
    <loading-root></loading-root>
    <!-- Utilizar v-if para mostrar u ocultar el componente header -->
    <header-root></header-root>
    <main class="root-content">
      <router-view />
    </main>
    <footer-root></footer-root>
  </div>
</template>
