<template>
  <div v-if="!disabled" class="container">
    <div class="columns is-centered is-multiline pt-5">
      <div class="column is-7">
        <div v-if="order.tmp_confirmate_order == 1" class="box py-6 has-text-centered">
          <p class="title is-3">Orden Confirmada</p>
          <p class="subtitle is-3"><b>#{{ order.order_code }}</b></p>
        </div>
        <div v-else id="paypal-button-container" class="box py-6"></div>
      </div>
      <div class="column is-5">
        <div class="order-details box">
          <p class="title is-5">Confirmar Orden</p>
          <p class="subtitle is-6 m-0"><b>Fecha:</b> {{ formatDate(transportSchedule.departure_time) }}</p>
          <p class="subtitle is-6 m-0"><b>Hora:</b> {{ formatTime(transportSchedule.departure_time) }}</p>
          <p class="subtitle is-6 m-0"><b>Tipo de transporte:</b> {{ transportSchedule.transport_type ? 'Tren' : 'Autobus' }}</p>
          <p class="subtitle is-6 m-0"><b>Tipo de boleto:</b> {{ order.ticket_type ? capitalizeFirstLetter(order.ticket_type) : '' }}</p>
          <p class="subtitle is-6 m-0"><b>Cantidad:</b> {{ order.number_of_tickets }}</p>
          <p class="subtitle is-6 m-0"><b>Pasajeros:</b></p>

          <ul>
            <li v-for="(passenger, index) in passengers" :key="index">
              <p class="subtitle is-6 m-0">
                {{ passenger.first_name }} {{ passenger.last_name }} - Asiento: {{ capitalizeFirstLetter(passenger.seat) }}
              </p>
            </li>
          </ul>
          <hr />
          <p class="subtitle is-6 m-0">
            <b>Subtotal: {{ formatCurrency(order.subtotal) }}</b>
          </p>
          <p class="subtitle is-6 m-0">
            <b>Descuentos: {{ formatCurrency(0) }}</b>
          </p>
          <hr />
          <p class="subtitle is-6 m-0">
            <b>Total: {{ formatCurrency(order.total) }}</b>
          </p>
          <br>
          <p class="subtitle is-6 m-0">
            <b v-if="order.tmp_confirmate_order == 0"><b>No Pagado</b></b>
            <b v-else><b>Pagado</b></b>
          </p>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'confirmateOrder',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmate: false,
      disabled: false,
      isLoading: true,
      order: {},
      passengers: [],
      transportSchedule: {},
      paypalKey: process.env.VUE_APP_PAYPAL_KEY,
    };
  },
  computed: {
    ...mapState({
      orderData: (state) => state.reservations.orderData,
    }),
  },
  mounted() {
    this.getOrderInfo();
  },
  watch: {
    reservation: {
      handler() {
        this.initPaypal();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getOrderData: 'reservations/getOrderData',
      updateOrderPaypal: 'reservations/updateOrderPaypal',
    }),
    async getOrderInfo() {
      await this.getOrderData({ code: this.code });

      if (!this.orderData.order) {
        await Swal.fire({
          title: 'No se encontro la información de la orden',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });

        this.disabled = true;
      } else {
        this.order = this.orderData.order;
        this.passengers = this.orderData.passengers;
        this.transportSchedule = this.orderData.transportSchedule;

        if (this.order.tmp_confirmate_order == 1) {
          this.confirmate = true;
        } else {
          this.initPaypal();
        }
      }

      this.isLoading = false;
    },
    initPaypal() {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalKey}&currency=MXN`;
      script.onload = () => {
        this.renderPayPalButton();
      };
      document.body.appendChild(script);
    },
    renderPayPalButton() {
      const { transportSchedule, order } = this.orderData;

      const ticketPrice = parseFloat((order.total / order.number_of_tickets).toFixed(2));

      // Asegúrate de que total y subtotal son números
      const total = Number(order.total);
      const subtotal = Number(order.subtotal);

      // Formatear los valores
      const formattedTotal = isNaN(total) ? '0.00' : total.toFixed(2);
      const formattedSubtotal = isNaN(subtotal) ? '0.00' : subtotal.toFixed(2);

      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: 'MXN',
                    value: formattedTotal, // Total de la reserva
                    breakdown: {
                      item_total: {
                        currency_code: 'MXN',
                        value: formattedSubtotal, // Subtotal
                      },
                      shipping: {
                        currency_code: 'MXN',
                        value: '0.00', // Si aplica, incluye costo de envío
                      },
                      tax_total: {
                        currency_code: 'MXN',
                        value: '0.00', // Si aplica, incluye impuestos
                      },
                    },
                  },
                  description: `Reserva para ${order.number_of_tickets} boletos de tipo ${order.ticket_type} (${transportSchedule.transport_type})`, // Descripción mejorada
                  custom_id: transportSchedule.id, // ID personalizado
                  invoice_number: `INV-${transportSchedule.id}`, // Número de factura
                  items: [
                    {
                      name: `Boleto ${order.ticket_type}`,
                      description: `Boleto para ${order.number_of_tickets} personas, tipo: ${order.ticket_type}, transporte: ${transportSchedule.transport_type}, horarios: ${transportSchedule.departure_time} - ${transportSchedule.arrival_time}`,
                      sku: transportSchedule.id + transportSchedule.transport_type + transportSchedule.departure_time,
                      unit_amount: {
                        currency_code: 'MXN',
                        value: ticketPrice, // Precio por boleto
                      },
                      quantity: order.number_of_tickets, // Cantidad de boletos
                    },
                  ],
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const details = await actions.order.capture();

              if (details && details.id) {
                console.log('Detalles de la transacción:', details);

                // Crear el objeto orderData fusionando la reserva con los detalles de pago
                const orderDataUpdate = {
                  ...this.orderData,
                  paymentDetails: {
                    details: details,
                    transactionId: details.id,
                    paymentId: details.purchase_units[0].payments.captures[0].id,
                    payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                    payerEmail: details.payer.email_address,
                    status: details.status,
                    amount: details.purchase_units[0].amount.value,
                    currency: details.purchase_units[0].amount.currency_code,
                    orderId: data.orderID,
                  },
                };

                console.log('Datos a enviar en createOrder:', orderDataUpdate);

                // Llama a createOrder con los detalles completos
                this.updateOrderPaypal(orderDataUpdate);

                // SweetAlert2 para mostrar el mensaje de éxito
                await Swal.fire({
                  title: 'Transacción completada',
                  text: 'Transacción completada por ' + details.payer.name.given_name,
                  icon: 'success',
                  confirmButtonText: 'Aceptar',
                });

                // Recargar la página después de que el usuario cierre el modal
                location.reload();
              } else {
                throw new Error('No se pudieron obtener los detalles de la transacción');
              }
            } catch (error) {
              console.error('Error capturando la transacción:', error);
              await Swal.fire({
                title: 'Error en la transacción',
                text: 'Hubo un problema al procesar el pago. Por favor, inténtalo de nuevo.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          },
          onError: (err) => {
            console.error('PayPal Checkout Error:', err);
            Swal.fire({
              title: 'Error',
              text: 'Ocurrió un error durante el proceso de pago.',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          },
        })
        .render('#paypal-button-container');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', options);
    },
    formatTime(dateString) {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const time = new Date(dateString);
      return time.toLocaleTimeString('es-ES', options);
    },
    formatCurrency(value) {
      return `$${parseFloat(value).toFixed(2)} MXN`;
    },
  },
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí */
</style>
