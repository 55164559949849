<template>
  <div class="seats">
    <div class="columns is-multiline">
      <div class="column is-5">
        <b-carousel-list :data="vehicles" :items-to-show="1" :repeat="true" :arrow-hover="false">
          <template #item="vehicle">
            <div class="map box">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 front map-info mb-5">
                  <p>Autobus {{ vehicle.vehicle_number }}</p>
                </div>

                <div class="column is-12 front map-info">
                  <p>Frente</p>
                </div>

                <div class="seats-rows column is-one-fifth">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 0)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-one-fifth">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 1)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-one-fifth">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 2)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, hidden: seat.hidden }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-one-fifth">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 3)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, wc: seat.wc }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="seats-rows column is-one-fifth">
                  <div
                    v-for="(seat, index) in getSeatsForVehicle(vehicle.id, 4)"
                    :key="index"
                    class="seat"
                    :class="{ available: seat.available, selected: seat.selected, wc: seat.wc }"
                  >
                    <input type="checkbox" :id="'seat-' + seat.id" :value="seat.id" @change="toggleSeat(seat)" :disabled="!seat.available" />
                    <p><i class="fas fa-couch"></i></p>
                    <label :for="'seat-' + seat.id"> {{ seat.label }}</label>
                  </div>
                </div>

                <div class="column is-12 under map-info">
                  <p>Posterior</p>
                </div>
              </div>
            </div>
          </template>
        </b-carousel-list>
      </div>
      <div class="column is-7">
        <div class="box">
          <h2 class="title is-4">Información sobre los asientos</h2>
          <ul>
            <li>
              <p><span class="info-icon has-background-secondary"></span>Disponible</p>
            </li>
            <li>
              <p><span class="info-icon has-background-warning"></span>Seleccionado</p>
            </li>
            <li>
              <p><span class="info-icon"></span>Ocupado</p>
            </li>
            <li>
              <p><span class="info-icon has-background-info"></span>Baños</p>
            </li>
            <!--             <b-button class="mt-5" type="is-primary" expanded>Reservar Asientos</b-button>
 -->
          </ul>
        </div>
        <div class="box bus-map">
          <h2 class="title is-4">Mapa</h2>
          <figure class="image">
            <img class="card-img" src="@/assets/images/vagon-mapa.jpg" alt="Logo" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      seats: [
        [
          // Fila 1
          { id: '1', label: '1', available: true, selected: false },
          { id: '5', label: '5', available: true, selected: false },
          { id: '9', label: '9', available: true, selected: false },
          { id: '13', label: '13', available: true, selected: false },
          { id: '17', label: '17', available: true, selected: false },
          { id: '21', label: '21', available: true, selected: false },
          { id: '23', label: '23', available: true, selected: false },
          { id: '27', label: '27', available: true, selected: false },
          { id: '31', label: '31', available: true, selected: false },
          { id: '35', label: '35', available: true, selected: false },
          { id: '39', label: '39', available: true, selected: false },
          { id: '43', label: '43', available: true, selected: false },
        ],
        [
          // Fila 2
          { id: '2', label: '2', available: true, selected: false },
          { id: '6', label: '6', available: true, selected: false },
          { id: '10', label: '10', available: true, selected: false },
          { id: '14', label: '14', available: true, selected: false },
          { id: '18', label: '18', available: true, selected: false },
          { id: '22', label: '22', available: true, selected: false },
          { id: '24', label: '24', available: true, selected: false },
          { id: '28', label: '28', available: true, selected: false },
          { id: '32', label: '32', available: true, selected: false },
          { id: '36', label: '36', available: true, selected: false },
          { id: '40', label: '40', available: true, selected: false },
          { id: '44', label: '44', available: true, selected: false },
        ],
        [
          // Fila 3
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '', label: '', available: false, selected: false, hidden: true },
          { id: '45', label: '45', available: true, selected: false },
        ],
        [
          // Fila 3
          { id: '3', label: '3', available: true, selected: false, wc: false },
          { id: '7', label: '7', available: true, selected: false, wc: false },
          { id: '11', label: '11', available: true, selected: false, wc: false },
          { id: '15', label: '15', available: true, selected: false, wc: false },
          { id: '19', label: '19', available: true, selected: false, wc: false },
          { id: '', label: '', available: false, selected: false, wc: true },
          { id: '25', label: '25', available: true, selected: false, wc: false },
          { id: '29', label: '29', available: true, selected: false, wc: false },
          { id: '33', label: '33', available: true, selected: false, wc: false },
          { id: '37', label: '37', available: true, selected: false, wc: false },
          { id: '41', label: '41', available: true, selected: false, wc: false },
          { id: '46', label: '46', available: true, selected: false, wc: false },
        ],
        [
          // Fila 4
          { id: '4', label: '4', available: true, selected: false, wc: false },
          { id: '8', label: '8', available: true, selected: false, wc: false },
          { id: '12', label: '12', available: true, selected: false, wc: false },
          { id: '16', label: '16', available: true, selected: false, wc: false },
          { id: '20', label: '20', available: true, selected: false, wc: false },
          { id: '', label: '', available: false, selected: false, wc: true },
          { id: '26', label: '26', available: true, selected: false, wc: false },
          { id: '30', label: '30', available: true, selected: false, wc: false },
          { id: '34', label: '34', available: true, selected: false, wc: false },
          { id: '38', label: '38', available: true, selected: false, wc: false },
          { id: '42', label: '42', available: true, selected: false, wc: false },
          { id: '47', label: '47', available: true, selected: false, wc: false },
        ],
        // Añade más filas según sea necesario
      ],
      selectedSeats: [],
      seatsArrayNew: [],
    };
  },
  computed: {
    ...mapState({
      schedule_id: (state) => state.reservations.reservation.transportSchedule.id,
      transport_type: (state) => state.reservations.reservation.transportSchedule.transport_type,
      ticket_type: (state) => state.reservations.reservation.ticket_type,
      ticket_quantity: (state) => state.reservations.reservation.ticket_quantity,
      vehicles: (state) => state.reservations.reservation.vehicles,
      reservationSeats: (state) => state.reservations.reservation.seats,
    }),
  },
  async mounted() {
    await this.loadVehicles();
  },
  watch: {
    async ticket_type(newVal) {
      if (newVal === 'ejecutivo' || newVal === 'bar') {
        await this.loadVehicles();
      }
    },
    async selectedSeats(newVal) {
      const seats = { ['seats']: newVal };
      this.setDataReservation(seats);
    },
  },
  methods: {
    ...mapActions({
      fetchVehicles: 'reservations/fetchVehicles',
    }),
    ...mapMutations({
      setDataReservation: 'reservations/SET_DATA_RESERVATION',
    }),
    toggleSeat(seat) {
      // Verificar si el asiento ya está seleccionado
      const seatIndex = this.selectedSeats.findIndex((s) => s.id === seat.id);

      if (seatIndex !== -1) {
        // Si el asiento está seleccionado, lo deseleccionamos
        seat.selected = false;
        this.selectedSeats.splice(seatIndex, 1); // Eliminar el objeto del asiento de la selección
      } else {
        if (this.selectedSeats.length >= this.ticket_quantity) {
          // Mostrar mensaje de error si se excede la cantidad permitida
          Swal.fire({
            icon: 'error',
            title: '¡Error!',
            text: `Has alcanzado el límite de selección de asientos. Solo puedes seleccionar hasta ${this.ticket_quantity} asiento${
              this.ticket_quantity > 1 ? 's' : ''
            }.`,
            confirmButtonText: 'Aceptar',
          });
          return; // Salir del método si se excede la cantidad
        }

        // Si el asiento no está seleccionado, lo seleccionamos
        seat.selected = true;
        this.selectedSeats.push(seat);
      }
    },
    async loadVehicles() {
      let vehicleType = 'bus';

      if (this.transport_type === 'train' && this.ticket_type === 'ejecutivo') {
        vehicleType = 'train';
      }

      if (this.transport_type === 'train' && this.ticket_type === 'bar') {
        vehicleType = 'train-bar';
      }

      const params = {
        transport_schedule_id: this.schedule_id,
        vehicle_type: vehicleType,
      };

      await this.fetchVehicles(params);

      this.updateSeatsAvailability();
    },
    updateSeatsAvailability() {
      // Crear una copia del array que contiene la estructura de vehículos y sus asientos
      this.seatsArrayNew = this.vehicles.map((vehicle) => {
        // Hacer una copia de los asientos
        const seatsForVehicle = this.seats.map((row) => {
          return row.map((seat) => {
            // Buscar el asiento correspondiente en el vehículo
            const vehicleSeat = vehicle.seats.find((v) => v.seat_number === seat.label);
            // Devolver el asiento con la disponibilidad actualizada
            return {
              ...seat,
              id: vehicleSeat ? vehicleSeat.id : seat.id,
              vehicleId: vehicle.id,
              vehicleNumber: vehicle.vehicle_number,
              available: vehicleSeat && vehicleSeat.is_available == '1' && vehicleSeat.is_reserved == '0' ? true : false,
            };
          });
        });

        return {
          vehicleId: vehicle.id, // Suponiendo que cada vehículo tiene un ID
          vehicleNumber: vehicle.vehicle_number, // Número del vehículo
          seats: seatsForVehicle,
        };
      });
    },
    getSeatsForVehicle(vehicleId, index) {
      // Buscar el vehículo en la lista de vehículos
      const vehicle = this.seatsArrayNew.find((v) => v.vehicleId === vehicleId);

      // Si el vehículo existe, retornar sus asientos
      return vehicle ? vehicle.seats[index] : []; // Retornar un array vacío si no se encuentra el vehículo
    },
  },
};
</script>

<style>
.map {
  max-width: 400px;
  margin: auto;
  display: block;
}

.map-info {
  background: #004e6d;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.seats-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seat {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #000;
  flex-direction: column;
}

.seat input {
  display: none;
}

.seat label {
  cursor: pointer;
  font-size: 12px;
}

.seat.available {
  background-color: #091d61;
  color: #fff;
  border: none;
}

.seat.selected {
  background-color: #b5891a;
  border: none;
}

.seat.hidden {
  background-color: transparent;
  border: none;
  color: transparent;
}

.seat.wc {
  background-color: #29abe2;
  border: none;
  color: transparent;
}

.info-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #000;
}

.bus-map img {
  width: auto !important;
  height: 500px !important;
  margin: auto;
  display: block;
}

.seats .carousel-arrow span.icon.has-icons-left,
.seats .carousel-arrow span.icon.has-icons-right {
  top: 35px !important;
}
</style>
