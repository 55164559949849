import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true, // Asegura que las acciones, mutaciones y getters sean locales al módulo
  state,
  mutations,
  actions,
  getters
};
