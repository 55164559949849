<template>
  <div class="travel-mode-section">
    <div class="container has-text-centered">
      <h2 class="title is-3">¡Elige tu próxima aventura a Tequila, Jalisco!</h2>
      <div class="columns is-centered">
        <div class="column is-5">
          <figure class="image">
            <img src="@/assets/images/logo-autobus.png" />
          </figure>
          <b-button tag="router-link" :to="{ name: 'reservations-bus' }" type="is-secondary" size="is-medium" class="mt-3" rounded>
            Disfruta un Viaje en Autobús
          </b-button>
        </div>
        <div class="column is-5">
          <figure class="image">
            <img src="@/assets/images/logo-tren.png" />
          </figure>
          <b-button tag="router-link" :to="{ name: 'reservations-train' }" type="is-secondary" size="is-medium" class="mt-3" rounded>
            Vive la Experiencia en Tren
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.travel-mode-section {
  img {
    max-height: 300px;
    object-fit: contain;
    margin: 0 auto;
  }

  .container {
    margin-top: 50px;
  }

  .title {
    color: #2c3e50;
    font-weight: bold;
  }
}
</style>
