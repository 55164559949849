<template>
  <div class="tours">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-3 tours-images">
          <figure v-for="n in 3" :key="n" class="image is-1by1">
            <img class="tours-img" :src="require(`@/assets/images/plata/${n}.png`)" alt="Logo" />
          </figure>
        </div>
        <div class="column is-9 tours-content">
          <div class="box title first-text has-background-gray">
            <p class="has-text-white">TOUR PLATA</p>
          </div>
          <h3 class="title first-text">TOUR PLATA $850.00</h3>
          <p class="text">Tour con servicio de bebidas a bordo del autobús (tequila, refresco y agua).</p>
          <h3 class="title">Tenemos dos puntos de salida:</h3>
          <p class="text">
            1.- Rotonda de los Jaliscienses Ilustres en el centro de Guadalajara, recepción 8:30 am salida 9:00 am. <br>
            2.- Cámara de Comercio de Guadalajara Av. Vallarta 4095, recepción 9:30 am para salida 10:00 am.
          </p>
          <h3 class="title">Itinerario</h3>
          <p class="text">
            09:30 Registro y entrega de pulseras en Cámara de Comercio de Guadalajara Av. Vallarta 4095. <br>
            10:00 Salida hacia Tequila. <br>
            11:15 Llegada a los campos agaveros de Casa Sauza, demostración de la jima del agave y plantación. <br>
            12:30 Visita a bodega de añejamiento “La Constancia” y fábrica “La Perseverancia” de Casa Sauza. <br>
            Esta experiencia no incluye comida, cantarito, ni espectáculo. <br>
            16:45 Regreso a Guadalajara. <br>
            18:15 Llegada a Cámara de Comercio de Guadalajara. <br>
          </p>
          <div class="reservations box has-background-gray">
            <a
              class="title has-text-white"
              href="https://wa.me/523335592398?text=¡Hola!%20Necesito%20información%20sobre%20los%20tours%20a%20Tequila,%20Jalisco."
              target="_blank"
              >INFORMES Y RESERVACIONES: <i class="fab fa-whatsapp"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>