<template>
  <div class="about-us">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-3 about-images">
          <figure v-for="n in 4" :key="n" class="image is-1by1">
            <img class="about-img" :src="require(`@/assets/images/about-us/${n}.png`)" alt="Logo" />
          </figure>
        </div>
        <div class="column is-9 about-content">
          <h3 class="title first-text">Tequila Express</h3>
          <p class="text">
            Es un recorrido organizado y operado por Cámara de Comercio de Guadalajara (anteriormente Tequila Express, que ahora es nuestro recorrido
            en tren) estos son nuestros dos productos turísticos.
          </p>
          <p class="text">
            Nuestro objetivo es rescatar, conservar y promover los 3 pilares que dan identidad al pueblo mexicano a nivel mundial: “El Tequila”, “La
            Charrería” y “El Mariachi”.
          </p>
          <p class="text">
            Nuestro recorrido turístico familiar te lleva a disfrutar del paisaje agavero de Tequila, Jalisco a bordo de un autobús de primera clase,
            teniendo como destino Casa Sauza.
          </p>
          <h3 class="title">
            Tour Tequila Express <br />
            Puntos de salida:
          </h3>
          <p class="text">
            - Rotonda de los Jaliscienses Ilustres en el centro histórico de Guadalajara y Cámara de Comercio de Guadalajara, Av. Vallarta 4095.
          </p>
          <h3 class="title">Campos Agaveros</h3>
          <p class="text">
            Viviremos la experiencia de plantar nuestro propio agave, podremos ver las diferencias que existen entre las cactáceas y las agaváceas y
            por último el jimador nos mostrará su habilidad para realizar la jima del agave.
          </p>
          <h3 class="title">Bodega de Añejamiento La Constancia de Casa Sauza</h3>
          <p class="text">
            Visitamos la bodega de añejamiento de Casa Sauza, donde reposan alrededor de 15,000.00 barricas de roble blanco añejando los más selectos
            tequilas además alberga una obra de arte impresionante: un bello mural en mosaico de cerámica con la historia de la bebida mexicana por
            excelencia.
          </p>
          <h3 class="title">Fábrica La Perseverancia de Casa Sauza</h3>
          <p class="text">
            La Perseverancia es la casa productora de Tequila Sauza donde se va descubriendo paso a paso la elaboración del tequila, fue fundada por
            Don Cenobio Sauza desde el año 1873. Cuando Don Cenobio Sauza compró la destilería ¨La Antigua Cruz¨ le cambió el nombre a La
            Perseverancia donde se lleva a cabo la magia de la fabricación.
          </p>
          <h3 class="title">Quinta Sauza</h3>
          <p class="text">
            Cruzando una de las calles céntricas y pintorescas de Tequila Pueblo Mágico se encuentra La Quinta Sauza. Esta antigua construcción data
            del año 1836 y estará esperándole para conocer sus históricos patios, jardines y disfrutar de unos ricos cantaritos.
          </p>
          <h3 class="title">Gastronomía y espectáculo mexicano</h3>
          <p class="text">
            Tal parece que las tradiciones mexicanas tienen la capacidad de hacer que quien las vive, se adueñe de ellas, aunque su tierra esté lejos
            de este místico y alegre país. Así sucede en Quinta Sauza durante la experiencia de Azul Express, pues además de la comida mexicana una
            increíble sorpresa nos espera.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>