<template>
  <div class="reservations-page py-6">
  <TravelMode />
  </div>
</template>

<script>
import TravelMode from './travelModeSelection.vue';

export default {
  name: 'Reservations',
  components: {
    TravelMode,
  },
  data() {
    return {
      activeDate: null,
    };
  },
};
</script>
