<template>
  <section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.state.ui.loading.isLoading;
    },
    isFullPage() {
      return this.$store.state.ui.loading.isFullPage;
    },
  },
};
</script>
